
import { Component, Provide, Vue } from "vue-property-decorator"
import AppDetailPage from "@/components/layout/AppDetailPage.vue"
import ConsultantContractSummary from "@/components/consultant/ConsultantContractSummary.vue"
import EventBus from "@/components/EventBus"
import {
  AcceptConsultantContractMutation,
  AcceptConsultantContractMutationMutation,
  ConsultantContractFragmentFragment,
  ConsultantContractsQuery,
  ConsultantContractState,
  DeclineConsultantContractMutation,
  DeclineConsultantContractMutationMutation,
  MyContractsQuery,
  MyContractsQueryQuery,
} from "@/gql"
import PeraltaUserAgreement from "@/components/templates/PeraltaUserAgreement.vue"
import ConflictOfInterestPolicy from "@/components/templates/ConflictOfInterestPolicy.vue"
import FeedBackMessage from "@/components/content/FeedBackMessage.vue"
import { DocumentNode } from "apollo-link"
import ActivityTimeline from "@/components/widgets/timeline/ActivityTimeline.vue"

@Component({
  components: {
    AppDetailPage,
    PeraltaUserAgreement,
    ConflictOfInterestPolicy,
    ConsultantContractSummary,
    FeedBackMessage,
    ActivityTimeline,
  },
})
export default class ContractDetail extends Vue {
  @Provide()
  contract: ConsultantContractFragmentFragment | undefined = undefined
  loading = false
  showAcceptContractModal = false
  showDeclineContractModal = false
  form = []
  rejectionReason = ""
  showUserAgreementDialog = false
  showConflictofInterest = false
  user: ConsultantContractFragmentFragment["application"] | null = null

  get query() {
    return this.can("core_team") ? ConsultantContractsQuery : MyContractsQuery
  }

  get refetchQueries() {
    return [
      {
        query: this.query,
        variables: {
          filter: {
            id: this.$route.params.id,
          },
        },
      },
    ]
  }
  get queryVars() {
    return {
      filter: {
        id: this.$route.params.id,
      },
    }
  }

  async onFetchResult(query: DocumentNode) {
    const result = await this.$apollo.query({
      query: query,
      variables: this.queryVars,
    })
    return result.data
  }

  async fetchContract() {
    this.loading = true

    const result = await this.onFetchResult(this.query)

    if (result) {
      this.loading = false

      if (this.can("core_team")) {
        this.user = result.consultantContracts?.data[0].application
        this.contract = result.consultantContracts?.data[0]
      } else {
        this.contract = result.consultantProfile.consultantContracts?.data[0]
        this.user = result.consultantProfile.consultantContracts?.data[0].application
      }
    } else {
      this.loading = false
    }
  }

  onAcceptTermsAndConditions() {
    this.showAcceptContractModal = false
    this.acceptContract()
  }

  async acceptContract() {
    this.loading = true

    const result = await this.mutate<AcceptConsultantContractMutationMutation>({
      mutation: AcceptConsultantContractMutation,
      variables: {
        id: this.contract!.id,
      },
      refetchQueries: this.refetchQueries,
      done: () => {
        this.loading = false
      },
      update: (cache, { data }) => {
        try {
          if (data && !data.acceptConsultantContract.error) {
            // Read the data from cache for this query.
            const data = cache.readQuery<MyContractsQueryQuery>({
              ...this.refetchQueries[0],
            })
            if (data && data.consultantProfile) {
              data.consultantProfile.consultantContracts.data[0].state =
                ConsultantContractState.Accepted
              data.consultantProfile.consultantContracts.data[0].acknowledgedAt = new Date()
            }
            cache.writeQuery({
              ...this.refetchQueries[0],
              data,
            })
          }
        } catch (error) {
          console.error(error)
        }
      },
    })

    if (result.data && !result.data.acceptConsultantContract.error) {
      this.addSuccess("Offer accepted successfully")
    }
  }

  async onDeclineContract() {
    this.loading = true

    const result = await this.mutate<DeclineConsultantContractMutationMutation>({
      mutation: DeclineConsultantContractMutation,
      variables: {
        id: this.contract!.id,
        declineReason: this.rejectionReason,
      },
      refetchQueries: this.refetchQueries,
      done: () => {
        this.loading = false
      },
      update: (cache, { data }) => {
        try {
          if (data && !data.declineConsultantContract.error) {
            // Read the data from cache for this query.
            const data = cache.readQuery<MyContractsQueryQuery>({
              ...this.refetchQueries[0],
            })
            if (data && data.consultantProfile) {
              data.consultantProfile.consultantContracts.data[0].state =
                ConsultantContractState.Declined
            }
            cache.writeQuery({
              ...this.refetchQueries[0],
              data,
            })
          }
        } catch (error) {
          console.error(error)
        }
      },
    })

    if (result.data && !result.data.declineConsultantContract.error) {
      this.addSuccess("Offer declined successfully")
      this.showDeclineContractModal = false
    }
  }

  async created() {
    this.fetchContract()
  }

  mounted() {
    EventBus.$on("update-contract-detail-query", () => {
      this.fetchContract()
    })
  }

  onApprovalComplete() {
    EventBus.$emit("update-contract-detail-query")
  }
}
